import React from 'react';
import Card from '../components/common/Card';
import ContentContainer from '../components/common/ContentContainer';

import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Button from '../components/common/Button';

const PostPurchasePage = () => (
  <Layout>
    <SEO title="Thank you" />
    <ContentContainer>
      <div className="w-1/2 mx-auto text-center">
        <Card>
          <h1 className="text-primary text-2xl font-bold text-center">
            Thank you for your purchase!
          </h1>
          <p className="mt-6 text-gray-600 sans-serif">
            Thank you for your recent online credit card transaction with
            Advancing Literacy, Teachers College. You should be receiving your
            credit card receipt via email shortly.
          </p>
          <p className="mt-6 text-gray-600 sans-serif">
            If you have any questions regarding your purchase, please contact us
            at{' '}
            <a
              href="mailto:al-finance@tc.columbia.edu"
              className="underline break-words"
            >
              al&#x2011;finance@tc.columbia.edu
            </a>
            .
          </p>
          <div className="mt-10">
            <Link to="/">
              <Button text="Back to Advancing Literacy" onClick={() => {}} />
            </Link>
          </div>
        </Card>
      </div>
    </ContentContainer>
  </Layout>
);

export default PostPurchasePage;
